<template>
  <home-header fixed/>
  <header-selete :style="{top:'47px'}" fixed :show-return="false" ref="selectkeyword"
                 placeholder='搜索服务商名称、服务类型'
                 @submit="updateList"/>
  <div class="dao-hang dao-hang-height">
    <van-tabs v-model:active="pageName" @change="pageChange">
      <van-tab title="找服务" name="FuWuDaTing">
      </van-tab>
      <van-tab title="找项目" name="XiangMuDaTing"></van-tab>
      <van-tab title="案例" name="AnLiDaTing"></van-tab>
    </van-tabs>
    <div class="shai-xuan">
      <div class="van-ellipsis">
        <select-top-address ref="addDiv" @change="updateList"/>
      </div>
      <div class="van-ellipsis">
        <select-top-fu-wu-type ref="typeDiv" @change="updateList"/>
      </div>
    </div>
  </div>
  <div class="dao-hang-height"></div>
  <list-page @load="getLoad" ref="xiaLa" top="190px" bottom="80px">
    <div
        v-for="(vo) in dataList"
        :key="vo.g_id">
      <fu-wu-shang-list
          :fu-wu-shang-info="vo"
          :to="{name:'FuWuShangInfo',query:{g_id:vo.g_id}}"
      />
    </div>
  </list-page>
  <footer-nav fixed path-name='FuWuDaTing'/>
</template>

<script>
//服务大厅
import HomeHeader from "../open/homeHeader";
import ListPage from "@/components/list/ListPage";
import {setTitle} from "@/api/GongJu";
import _ from "lodash";
import http from "@/api/http";
import HeaderSelete from "@/views/open/headerSelete";
import SelectTopAddress from "@/components/select/selectTopAddress";
import SelectTopFuWuType from "@/components/select/SelectTopFuWuType";
import FuWuShangList from "@/views/index/public/FuWuShangList";
import FooterNav from "@/views/open/footerNav";
import XEUtils from "xe-utils";

export default {
  name: 'FuWuDaTing',
  components: {FooterNav, FuWuShangList, SelectTopFuWuType, SelectTopAddress, HeaderSelete, HomeHeader, ListPage},
  beforeRouteEnter(to, from, next) { // 如果没有配置顶部按钮或isBounce,则beforeRouteEnter不用写
    next(vm => {
      // 滚动到原来的列表位置,恢复顶部按钮和isBounce的配置
      vm.$refs.xiaLa && vm.$refs.xiaLa.beforeRouteEnter()
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.xiaLa && this.$refs.xiaLa.beforeRouteLeave()
    next()
  },
  data() {
    return {
      dataList: [],
      where: {},
      FuWuDaTing: 'FuWuDaTing',
    }
  },
  deactivated() {
    this.setNav()
  },
  mounted() {
    this.setNav()
  },
  methods: {
    setNav() {
      setTitle(_.get(this.$route, 'meta.title'))
      this.pageName = 'FuWuDaTing'
    },
    getLoad({num, size}) {
      console.log(55555, this.$refs.addDiv.value)
      this.where.address_name = this.$refs.addDiv.getAddressName()
      this.where = {...this.where, ...this.$refs.addDiv.getALl()}
      this.where = {...this.where, ...this.$refs.typeDiv.getWhere()}
      this.where.keyword = this.$refs.selectkeyword.getKeyword()
      this.where.page = num
      this.where.data_length = size
      http.get('/gzhphp/fu_wu_shang/getFuWuDaTing', {params: this.where, load: false}).then(da => {
        if (da.code === 1) {
          if (num === 1) this.dataList = []
          XEUtils.each(da.data.data, n => {
            this.dataList.push(n)
          })
          this.$refs.xiaLa.endSuccess(da.data.data.length)
        }
      })

    },
    pageChange(page) {
      this.$router.push({name: page})
    },
    updateList() {
      this.$refs.xiaLa.resetData()
    }
  },
}
</script>

<style scoped lang="less">
@import "../../assets/da-ting.less";
</style>
