<template>
  <div class="fu-wu-shang" @click="showInfo">
    <div class="name">{{ fuWuShangInfo.g_name }}</div>
    <div class="fu-wu" v-if="fuWuTYpe().length">
      <biao-qian :list="fuWuTYpe()" :maxLength="5"></biao-qian>
    </div>
    <div class="cheng-jiao">
      服务次数：{{ getFuWuZhiLiang(fuWuShangInfo) }}次 | 成交金额：{{ jiEr() }} |
      好评：{{ getFwWupingFen(fuWuShangInfo.ping_fen) }}%
    </div>
    <div class="address">
      地区：{{ address_val }}
    </div>
  </div>
</template>

<script>


export default {
  name: 'FuWuShangList',
}
</script>
<script setup>
import BiaoQian from "../../open/biaoQian";
import {defineProps, computed} from "vue"
import {getChengJiaoErHanZi, getFuWuZhiLiang, getFwWupingFen, getFwType} from "@/api/lol_cmf_gys"
import _ from "lodash"
import {useRouter} from "vue-router";
import XEUtils from "xe-utils";

const router = useRouter()
let props = defineProps({
  fuWuShangInfo: Object,
  to: Object
})


function fuWuTYpe() {
  if (!props.fuWuShangInfo?.fu_wu_type) return []
  return getFwType(props.fuWuShangInfo.fu_wu_type)
}

function jiEr() {
  return getChengJiaoErHanZi(props.fuWuShangInfo)
}

let address_val = computed(() => {
  let val = _.get(props.fuWuShangInfo, 'php_address.name', [])
  if (_.isArray(val)) {
    return val.join(' / ')
  }
  return ''
})

function showInfo() {
  let to = props.to
  XEUtils.set(to, 'query.ran_id', XEUtils.uniqueId('page_'))
  router.push(to)
}
</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.fu-wu-shang {
  background-color: @white;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px #ECDDDD;
  line-height: 25px;

  .fu-wu {
    padding: 5px 0;
  }

  .name {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap; //不换行
    overflow: hidden; //超出隐藏
    text-overflow: ellipsis; //变成...
  }

  .cheng-jiao {
    color: @gray-5;
    white-space: nowrap; //不换行
    overflow: hidden; //超出隐藏
    text-overflow: ellipsis; //变成...
    font-size: 14px;
  }

  .address {
    font-size: 14px;
    color: @gray-5;
    white-space: nowrap; //不换行
    overflow: hidden; //超出隐藏
    text-overflow: ellipsis; //变成...
  }

}
</style>
