import XEUtils from "xe-utils";
import {unitConvert} from "@/api/GongJu";
import _ from 'lodash'
/**
 * 服务评分
 */
export function getFwWupingFen(ping_fen) {

    return XEUtils.toInteger(ping_fen)
}

/**
 * 服务数量
 */
export function getFuWuZhiLiang(fwsInfo) {
    return XEUtils.toInteger(_.get(fwsInfo,'cheng_jiao_ci_shu')) + XEUtils.toInteger(_.get(fwsInfo,'cheng_jiao_ci_shu_plus'))
}

/**
 * 成交金额 千分数
 */
export function getChengJiaoEr(fwsInfo) {
    let sum = XEUtils.toInteger(fwsInfo.cheng_jiao_jin_er) + XEUtils.toInteger(fwsInfo.cheng_jiao_jin_er_plus)
    return XEUtils.commafy(sum)
}
/**
 * 成交金额 带单位
 */
export function getChengJiaoErHanZi(fwsInfo){
    let sum = XEUtils.toInteger(fwsInfo.cheng_jiao_jin_er) + XEUtils.toInteger(fwsInfo.cheng_jiao_jin_er_plus)
    return unitConvert(sum)
}

/**
 * 服务类型传数组
 * @param fu_wu_type
 * @returns {*}
 */
export function getFwType(fu_wu_type) {
    fu_wu_type = XEUtils.toString(fu_wu_type).split(',')
        .filter(n => n !== '')
    return fu_wu_type
}
